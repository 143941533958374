$(document).ready(function(){
	var sRootUrl						= window.location.href;
	if(sRootUrl == 'http://localhost:3000/web/fortbildungsakademie/') {
		var sHomeLink						= '//localhost:3000/web/fortbildungsakademie';
	} else if(sRootUrl == 'http://127.0.0.1/web/fortbildungsakademie/') {
		var sHomeLink						= '127.0.0.1/web/fortbildungsakademie';
	} else if(sRootUrl == 'http://testingserver.ch/fortbildungsakademie/') {
		var sHomeLink						= 'http://testingserver.ch/fortbildungsakademie';
	} else if(sRootUrl == 'http://fortbildung.klifairs.ch/') {
		var sHomeLink						= 'http://fortbildung.klifairs.ch';
	}

	console.log(sRootUrl);

	var aUrl							= window.location.href.split('/');
	var sUrl							= aUrl[aUrl.length - 2];

	if(sRootUrl.indexOf('/anaesthesieforen/') > -1 || sRootUrl.indexOf('/anaesthesieforen-uebersicht/') > -1) {
		$('.active-navi-point').removeClass('active-navi-point');
		$('.navi-points').find('a[href*="anaesthesieforen"]').addClass('active-navi-point');
	} else if(sRootUrl.indexOf('/repetitorien-uebersicht/') > -1) {
		$('.active-navi-point').removeClass('active-navi-point');
		$('.navi-points').find('a[href*="repetitorien"]').addClass('active-navi-point');
	} else if(sRootUrl.indexOf('/kurse/') > -1) {
		$('.active-navi-point').removeClass('active-navi-point');
		$('.navi-points').find('a[href*="kurse"]').addClass('active-navi-point');
	}


	$('.navi-points>a').each(function(){
		var sFullLink				= $(this).attr('href');
		var aFullLinkSplit			= sFullLink.split('/');
		var sLink					= aFullLinkSplit[aFullLinkSplit.length - 2];

		if(sLink == sUrl) {
			$(this).addClass('active-navi-point');
		} else if (sHomeLink == sFullLink) {
			$(this).addClass('active-navi-point');
		}
	});


	$('#mobile-nav-trigger').on('click',function(){
		var iWindowOffset 	= $(window).scrollTop();
		var iNaviOffset		= $('h1').offset().top;
		var iOffset 		= (iNaviOffset - 5) - iWindowOffset ;
		$('#mobile-nav-trigger').parent().toggleClass('open-nav');
		$('.navi-points').css('top', iOffset + 'px');
		$('body').toggleClass('body-open-nav');
	});
});