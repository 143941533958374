$(document).ready(function() {
	var oBody						= $('html,body');

	//Scroll-Animation
	var smoothScroll				= function(iOffset) {
		var sDestination				= (iOffset == 0) ? iOffset : '+=' + iOffset;

		oBody.animate({
			scrollTop: sDestination
		}, 800);
		return false;
	}

	/* Nach oben Button */
	$("#to-the-top").on('click',function() {
		smoothScroll(0);
	});

	var handleAnimations					= function() {
		$('.animate').each(function(){
			var iOffsetTop					= $(this).offset().top - $(window).scrollTop() - ($(window).height() / 2 + $(window).height() / 4);
			if(iOffsetTop <= 0 ){
				if($(this).hasClass('outfaded')){
					$(this).removeClass('outfaded');
				}

				if($(this).hasClass('topdown')){
					$(this).removeClass('topdown');
				}

				if($(this).hasClass('bottomup')){
					$(this).removeClass('bottomup');
				}

				if($(this).hasClass('leftright')){
					$(this).removeClass('leftright');
				}

				if($(this).hasClass('rightleft')){
					$(this).removeClass('rightleft');
				}
			};
		});
	};

	handleAnimations();

	$(window).on('scroll',function() {
		if ($(this).scrollTop() > 100) {
			$("#to-the-top").fadeIn()
		} else {
			$("#to-the-top").fadeOut()
		}

		handleAnimations();
	});
});