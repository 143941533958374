$(document).ready(function(){
	var sTitle							= $('#post h1').text();
	var sContent						= '';
	
	var sDate 							= $('#post-text').find('p').text();
	sDate 								= sDate.replace('.', ' ');
	sDate 								= sDate.replace('.', ':');
	sDate 								= sDate.replace(',', ' ');
	sDate 								= sDate.replace(/  /g,'-');
	sDate 								= sDate.replace(/ /g,'-');
	
	var aDate 							= sDate.split('-');

	var sDay							= aDate[0];
	var sMonth							= aDate[1];

	switch(sMonth) {
		case 'Januar':
			sMonth 						= '01';
		break;
		case 'Februar':
			sMonth 						= '02';
		break;
		case 'März':
			sMonth 						= '03';
		break;
		case 'April':
			sMonth 						= '04';
		break;
		case 'Mai':
			sMonth 						= '05';
		break;
		case 'Juni':
			sMonth 						= '06';
		break;
		case 'Juli':
			sMonth 						= '07';
		break;
		case 'August':
			sMonth 						= '08';
		break;
		case 'September':
			sMonth 						= '09';
		break;
		case 'Oktober':
			sMonth 						= '10';
		break;
		case 'November':
			sMonth 						= '11';
		break;
		case 'Dezember':
			sMonth 						= '12';
		break;
	}

	var sYear							= aDate[2];
	var sTime							= (aDate[3] != undefined) ? aDate[3] : '00';

	var sNewDate						= sDay + '.' + sMonth + '.' + sYear + ' ' + sTime + ':00';

	var sLink							= window.location.href;
	if(sLink.indexOf("127.0.0.1") > -1){
		var sAdditionUrl					= '/web/fortbildungsakademie';
	} else if (sLink.indexOf("testingserver.ch") > -1) {
		var sAdditionUrl					= '/fortbildungsakademie';
	} else {
		var sAdditionUrl					= '';
	}
	var sAjaxUrl						= 'webcal://' + window.location.host + sAdditionUrl + '/wp-content/themes/sage-master/inc/get-ics.php';

	if($('.accordion').length){
		$('.accordion').each(function(){
			if($(this).find('h2').text().trim().toLowerCase() == 'programm') {
				sContent					= $(this).find('.accordion-body').html().toString().replace(/(<([^>]+)>)/ig,"").trim();
			}
		});
	}

	var sendData						= function() {
		var oParams 			= {
			title:			sTitle,
			content:		sContent,
			date:			sNewDate,
			url:			sLink
		};

		var aParams 			= $.param(oParams);

		var sIcsUrl 			= sAjaxUrl + '?' + aParams;

		/*$.ajax({
			url: sAjaxUrl,
			type: 'POST',
			data: aParams,
			success: function(data){
				console.log(data);
			}
		});*/

		window.open(sIcsUrl,"_blank");
	};


	$('#ics').on('click', function(e){
		sendData();
		e.preventDefault();
	});
});