$(window).load(function(){
	var sURL							= document.URL;
	if(sURL.indexOf("127.0.0.1") > -1){
		var sRootUrl					= 'http://127.0.0.1/web/fortbildungsakademie/';
		var sAjaxUrl					= sRootUrl + 'onlineakademie/';
	} else if (sURL.indexOf("testingserver.ch") > -1) {
		var sRootUrl					= 'http://www.testingserver.ch/fortbildungsakademie/';
		var sAjaxUrl					= sRootUrl + 'onlineakademie/';
	} else {
		var sRootUrl					= 'http://leitbild.klifairs.ch/';
		var sAjaxUrl					= sRootUrl + 'onlineakademie/';
	}

	var oSlugSelectElement				= $('#filter select');
	var aSlugs							= {
		date:				$('.date-filter').val(),
		alphabet:			'',
		reference:			''
	};
	
	var getSelectSlugs					= function(oFilterElement){
		var	sClass							= oFilterElement.attr('class').split('-filter')[0];
		switch(sClass) {
			case 'date':
				aSlugs.date					= oFilterElement.val();
				$('.reference-filter option:selected').prop('selected', false);
				$('.reference-filter option:first-child').prop('selected', true);
				$('.alphabet-filter option:selected').prop('selected', false);
				$('.alphabet-filter option:first-child').prop('selected', true);
			break;
			case 'alphabet':
				aSlugs.alphabet				= oFilterElement.val();
				aSlugs.reference			= '';

				$('.reference-filter option:selected').prop('selected', false);
				$('.reference-filter option:first-child').prop('selected', true);
				$('.date-filter option:selected').prop('selected', false);
				$('.date-filter option:first-child').prop('selected', true);
			break;
			case 'reference':
				aSlugs.reference			= oFilterElement.val();
				aSlugs.alphabet				= '';

				$('.alphabet-filter option:selected').prop('selected', false);
				$('.alphabet-filter option:first-child').prop('selected', true);
				$('.date-filter option:selected').prop('selected', false);
				$('.date-filter option:first-child').prop('selected', true);
			break;
		}
		return aSlugs;
	}
	
	var sendSlugs						=	function(aGottenSlugs){
		var oJsonString						= aGottenSlugs;
		
		$.ajax({
			url: sAjaxUrl,  
			cache: false,
			async: false,
			type: "POST",
			data: {oData: oJsonString},
			success: function(data){
				var oNewPosts = $(data).filter('#body').find('#vids').html();
				$('#vids').fadeOut(function(){
					$(this).children().remove();
					$(this).append(oNewPosts);
					$(this).fadeIn();		
				});
			}
		});
	}

	//Dropdownfilter
	oSlugSelectElement.on('change', function(){
		sendSlugs(getSelectSlugs($(this)));
	});
});