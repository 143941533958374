$(document).ready(function(){
	var sIframeStart					= '<iframe class="main-width center" width="853" height="480" src="https://www.youtube-nocookie.com/embed/';
	var sIframeEnd						= '?rel=0&amp;controls=1&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>';
	var showOverlay						= function(oVideo, sCase) {
		var sVideoID					= oVideo.attr('vid-id');
		var sIframeHtml					= sIframeStart + sVideoID + sIframeEnd;
		var sShareLink					= 'https://www.youtube.com/watch?v=' + sVideoID;

		switch(sCase) {
			case 'post':
				var sTitle						= $('#post-text>h1').text();
				var sReverend					= $('#post-text>span').text();
			break;
			case 'onlineacademy':
				var sTitle						= oVideo.find('h2').text();
				var sReverend					= oVideo.find('p').text();
			break;
		}

		$('body').addClass('overlay-shown');
		$('#iframe-overlay').fadeIn(function(){
			$(this).append('<h2>' + sTitle + '</h2>');
			$(this).append('<p>Referent: ' + sReverend + '</p>');
			$(this).append(sIframeHtml);
			$(this).append('<div id="#social-share">
				<p>Dieses Video Teilen:</p>
				<a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=' + sShareLink + '"><i class="fa fa-facebook"></i></a>
				<a target="_blank" href="https://twitter.com/home?status=' + sShareLink + '"><i class="fa fa-twitter"></i></a></div>
			');
		});
	};

	var closeOverlay					= function() {
		$('body').removeClass('overlay-shown');
		$('#iframe-overlay').fadeOut(250, function(){
			$(this).find('iframe').remove();
			$(this).find('h2').remove();
			$(this).find('p').remove();
			$(this).find('div').remove();
		});
	};

	$('#vids').delegate('.iframe-video','click',function(){
		showOverlay($(this), 'onlineacademy');
	});

	$('.post-footer').delegate('.iframe-video','click',function(){
		showOverlay($(this), 'post');
	});

	$('#close-overlay').on('click', function(){
		closeOverlay();
	});

	//Escape zum schliessen des Overlays
	$('body').keyup(function(e) {
		switch(e.keyCode){
			case 27:
				closeOverlay();
			break;
		}
	});
});