$(document).ready(function(){
	var scrollToOpenedElement					= function() {
		if($('.open').length){
			var oElement					= $('.open');
		} else {
			var oElement					= $('.accordion:first-child');
		}
		$('html,body').animate({
			scrollTop: oElement.offset().top
		});
	};

	$('.accordion-header').on('click', function(){
		if($(this).parent().hasClass('open')){
			$(this).parent().removeClass('open');
		} else {
			$('.open').removeClass('open');
			$(this).parent().addClass('open');
			setTimeout(function(){
				scrollToOpenedElement();
			}, 300);
		}
	});


	$('.close').on('click', function(){
		$('.open').removeClass('open');
		setTimeout(function(){
			scrollToOpenedElement();
		}, 300);
	});
});